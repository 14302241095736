import React from "react";
import { MoneyConvertor } from "../MoneyConvertor";
import "./calculadora.css";

const Calculadora = ({ pageContext, location }) => {
  const searchLangParams =
    new URLSearchParams(location.search).get("lang") || "es";

  const flagsSendities =
    searchLangParams === "es"
      ? pageContext.flagsPricingSenditiesEs
      : pageContext.flagsPricingSenditiesEn;

  return (
    <div className="money-convertor_calculator-page">
      <MoneyConvertor
        locationName={"link"}
        flagsPricingSendities={flagsSendities}
        country_code={pageContext.country_code}
        location={location.href}
        lang={searchLangParams}
      />
    </div>
  );
};

export default Calculadora;

//http://localhost:8000/calculadora?country=colombia&&lang=en

//    style="
//         max-width: 900px;
//         min-width: 100%;
//         height: 100vh;
//         background-color: red;
//       "
//  >
//    <iframe
//      name="sendity"
//      loading="lazy"
//      title="sendity calculator"
//      allow="fullscreen"
//      scrolling="no"
//      style="
//           width: 100%;
//           height: 100%;
//           border: none;
//         "
//      src="https://insite.eu.ngrok.io/calculadora"
//    ></iframe>
//  </div>
